<template>
  <el-dialog
    append-to-body
    :title="title"
    ref="dialog"
    width="500px"
    :visible.sync="show"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :beforeClose="beforeClose"
  >
    <el-form ref="form" :model="form.data" label-width="100px" :rules="form.rules">
      <el-form-item label="所属机构" prop="org_id">
        <el-cascader
          v-model="form.data.org_id"
          :options="OrgTree"
          :props="{ checkStrictly: true ,label:'name',value:'id',emitPath:false}"
          placeholder="请选择所属机构"
          clearable
          size="small"
          :showAllLevels="false"
          :disabled="type==='update'"
        >
          <template slot-scope="{  data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="上级角色" prop="pid">
        <el-cascader
          v-model="form.data.pid"
          :options="roleTree"
          :props="{ checkStrictly: true ,label:'name',value:'id',emitPath:false}"
          placeholder="请选择上级角色"
          clearable
          size="small"
          :showAllLevels="false"
        >
          <template slot-scope="{  data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="角色名称" prop="name">
        <el-input v-model="form.data.name" placeholder="请输入角色名称" size="small" clearable maxlength="20"></el-input>
      </el-form-item>

      <el-form-item label="描述">
        <el-input v-model="form.data.remark" placeholder="请输入描述" size="small" clearable maxlength="100"></el-input>
      </el-form-item>
      <el-form-item label="规则" prop="rules" class='tree_container' v-if="RoleRuleTree.length > 0 ">
        <el-tree
          :data="RoleRuleTree"
          show-checkbox
          node-key="id"
          ref="tree"
          highlight-current
          :default-expanded-keys="form.data.rules"
          :default-checked-keys="form.data.rules"
          :props="{label: 'name', isLeaf: data => !data.has_child}"
        ></el-tree>
      </el-form-item>
      <el-form-item label="是否启用" prop="status">
        <el-switch
          v-model="form.data.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
        ></el-switch>
      </el-form-item>
      <div class="submit">
        <el-button size="small" @click="toggle(false)" :loading="form.loading">取消</el-button>
        <el-button type="primary" size="small" @click="submit" :loading="form.loading">确定</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import Role from '@/api/setting/role';
import Public from '@/api/public';
export default {
  name: '',
  props: {
    title: String,
    type: String,
    OrgTree: Array,
  },
  components: {},
  data() {
    return {
      show: false,
      form: {
        data: {
          pid: '',
          name: '',
          status: 1,
          remark: '',
          rules: [],
          org_id: 0,
        },
        loading: false,
        rules: {
          pid: [{ required: true, message: '请选择上级角色', trigger: 'change' }],
          name: [{ required: true, message: '请输入角色名称', trigger: 'change' }],
          status: [{ required: true, message: '请选择是否启用', trigger: 'change' }],
          rules: [{ required: true, message: '请选择规则', trigger: 'change' }],
          org_id: [{ required: true, message: '请选择所属机构', trigger: 'change' }],
        },
      },
      RoleRuleTree: [],
      roleTree: [],
    };
  },
  methods: {
    beforeClose() {
      this.reset();
      this.toggle(false);
    },
    getOrgTree() {
      Public.components({
        OrgTree: this.form.data.org_id,
      }).then((res) => {
        this.form.data.org_id = res.OrgTree[0].id;
      });
    },
    toggle(show, org_id, data) {
      console.log(data);
      this.show = show;
      if (show) {
        this.form.data.org_id = org_id;
        this.getOrgTree();
        Public.components({
          RoleTree: this.form.data.org_id,
        }).then((res) => {
          this.roleTree = res.RoleTree;
        });
      }
      if (show && data) {
        let { org_id, name, status, remark, rules, pid, id } = data;
        console.log(org_id, name, status, remark, rules, pid, id);
        this.form.data = { org_id, name, status, remark, rules, pid, id };
        this.$nextTick(() => {
          this.$refs.form.resetFields();
        });
      }
      if (!show) {
        this.$emit('changeShow', false);
      }
    },
    submit() {
      console.log(this.$refs.tree.getCheckedKeys());
      this.form.data.rules = this.$refs.tree.getCheckedKeys();
      console.log(this.form.data.rules);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.loading = true;
          let form = JSON.parse(JSON.stringify(this.form.data));
          if (this.type === 'update') {
            delete form.org_id;
            delete form.pid;
          }
          Role[this.type](form)
            .then(() => {
              this.$message.closeAll();
              this.$message.success(`${this.title}成功`);
              this.toggle(false);
              this.$emit('getList');
            })
            .finally(() => {
              this.form.loading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    reset() {
      this.$refs.form.resetFields();
    },
    // 获取对应的规则列表
    getRole() {
      Public.components({
        RoleRuleTree: this.form.data.pid,
      }).then((res) => {
        this.RoleRuleTree = res.RoleRuleTree;
        console.log(res);
      });
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {
    'form.data.pid': {
      handler: function (n) {
        if (n) {
          return this.getRole();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.submit {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}
.el-cascader {
  width: 100%;
}
.el-select {
  width: 100%;
}
.el-tree {
  max-height: 300px;
  height: 300px;
  align-self: center;
  overflow: auto;
}
::v-deep .el-dialog {
  margin: 5vh auto 0 auto !important;
}
::v-deep .el-dialog__body {
  height: 70vh;
}
</style>
